export const app = {
	id: 'balancete.ai',
	name: 'Balancete.ai',
	description:
		'Automatize o planilhamento e classificação de balancetes em menos de 10 segundos com o balancete.ai',
	site: 'balancete.ai',
	keywords: [
		'balancete.ai',
		'processamento de balancetes',
		'inteligência artificial para balancetes',
		'análise de balancetes M&A',
		'automatização de balancetes',
		'compilação de balancetes M&A',
		'ferramentas de M&A',
		'planilhamento automatizado',
		'análise financeira M&A',
		'geração de balancetes com IA',
		'plataforma de balancetes',
		'automação contábil',
		'balancetes para fusões e aquisições',
		'IA para análise financeira',
		'software de balancetes M&A',
	],
	author: {
		name: 'Balancete.ai',
		url: 'https://balancete.ai',
	},
	copyright: {
		name: 'Balancete.ai',
		url: 'https://balancete.ai',
	},
};
