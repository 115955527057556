import { useEffect, useState } from 'react';

export function useFirstInteraction(): boolean {
	// States
	const [hasInteracted, setHasInteracted] = useState(false);

	// Effects
	useEffect(() => {
		if (hasInteracted) return;

		const handleInteraction = () => {
			if (process.env.NODE_ENV === 'development')
				console.log('[+] User interacted');

			setHasInteracted(true);
			removeListeners();
		};

		const addListeners = () => {
			document.addEventListener('mousemove', handleInteraction);
			document.addEventListener('click', handleInteraction);
			document.addEventListener('keydown', handleInteraction);
			document.addEventListener('touchstart', handleInteraction);
		};

		const removeListeners = () => {
			document.removeEventListener('mousemove', handleInteraction);
			document.removeEventListener('click', handleInteraction);
			document.removeEventListener('keydown', handleInteraction);
			document.removeEventListener('touchstart', handleInteraction);
		};

		addListeners();

		return () => removeListeners();
	}, [hasInteracted]);

	return hasInteracted;
}
