import 'react-loading-skeleton/dist/skeleton.css';
import '../styles/global.css';

import { Suspense, useEffect } from 'react';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { DefaultSeo } from 'next-seo';

import { AresUIProvider, NavigationProgressBar } from 'aresui';
import { CodeKitConfig } from 'codekit';
import { SkeletonTheme } from 'react-loading-skeleton';
import { Toaster } from 'sonner';

import LoadingFullScreen from 'components/LoadingFullscreen';
import { app } from 'config/app';
import { ContextProviders } from 'contexts';
import { ThemeProvider as AppThemeProvider } from 'contexts/ThemeContext';
import { useFirstInteraction } from 'hooks/useFirstInteraction';
import { useTheme } from 'hooks/useTheme';
import * as gtag from 'services/gtag';
import { ThemeProvider as StyledThemeProvider } from 'styles/index';
import { GlobalStyle } from '../styles/global';

function MyApp(appProps: AppProps): JSX.Element {
	return (
		<Suspense fallback={<LoadingFullScreen />}>
			<AppThemeProvider>
				<Container {...appProps} />
			</AppThemeProvider>
		</Suspense>
	);
}

const Container = ({ Component, pageProps }: AppProps): JSX.Element => {
	// Hooks
	const theme = useTheme();
	const router = useRouter();
	const interacted = useFirstInteraction();

	// Effects
	useEffect(() => {
		const handleRouteChange = (url: string) => {
			gtag.pageview(url);
		};
		router.events.on('routeChangeComplete', handleRouteChange);

		return () => {
			router.events.off('routeChangeComplete', handleRouteChange);
		};
	}, [router.events]);

	return (
		<StyledThemeProvider theme={theme}>
			<GlobalStyle />

			<DefaultSeo title={app.name} />
			<CodeKitConfig />
			<NavigationProgressBar
				color={theme.colors.primary}
				showOnShallow={false}
			/>

			{interacted && (
				<>
					<Toaster theme={theme.name} />

					<Script
						strategy="afterInteractive"
						src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
					/>

					<Script
						id="google-analytics"
						strategy="afterInteractive"
						dangerouslySetInnerHTML={{
							__html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${gtag.GA_TRACKING_ID}');
          `,
						}}
					/>
				</>
			)}

			<AresUIProvider
				config={{ app }}
				theme={{
					colors: {
						primary: theme.colors.primary,
						background: theme.colors.background,
						line: theme.colors.line,
					},
				}}
			>
				<SkeletonTheme>
					<ContextProviders>
						<Component {...pageProps} />
					</ContextProviders>
				</SkeletonTheme>
			</AresUIProvider>
		</StyledThemeProvider>
	);
};

export default MyApp;
