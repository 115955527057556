import { AuthProvider } from './AuthContext';
import { DashboardProvider } from './DashboardContext';

interface ContextProvidersProps {
	children: JSX.Element;
}

export function ContextProviders(props: ContextProvidersProps): JSX.Element {
	return (
		<AuthProvider>
			<DashboardProvider>{props.children}</DashboardProvider>
		</AuthProvider>
	);
}
