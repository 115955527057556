/* eslint-disable @typescript-eslint/no-explicit-any */
export const GA_TRACKING_ID = 'G-HSQ1KZNQV3';

export const pageview = (url: string) => {
	(window as any).gtag?.('config', GA_TRACKING_ID, {
		page_path: url,
	});
};

type GTagEvent = {
	action: string;
	category: string;
	label: string;
	value?: number;
};

export const event = ({ action, category, label, value }: GTagEvent) => {
	(window as any).gtag?.('event', action, {
		event_category: category,
		event_label: label,
		value,
	});
};
