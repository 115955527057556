import { darken, invert, lighten, transparentize } from 'polished';
import { css } from 'styled-components';

import { Theme } from 'models/Theme';
import { readable } from 'utils/color';

export function rsuiteColors(theme: Theme) {
	const gray0 = theme.name === 'light' ? '#fff' : invert('#fff');
	const gray50 = theme.name === 'light' ? '#f7f7fa' : invert('#f7f7fa');
	const gray100 = theme.name === 'light' ? '#f2f2f5' : invert('#f2f2f5');
	const gray200 = theme.name === 'light' ? '#e5e5ea' : invert('#e5e5ea');
	const gray300 = theme.name === 'light' ? '#d9d9d9' : invert('#d9d9d9');
	const gray400 = theme.name === 'light' ? '#c5c6c7' : invert('#c5c6c7');
	const gray500 = theme.name === 'light' ? '#a6a6a6' : invert('#a6a6a6');
	const gray600 = theme.name === 'light' ? '#8e8e93' : invert('#8e8e93');
	const gray700 = theme.name === 'light' ? '#7a7a7a' : invert('#7a7a7a');
	const gray800 = theme.name === 'light' ? '#575757' : invert('#575757');
	const gray900 = theme.name === 'light' ? '#272c36' : invert('#272c36');

	const primary50 = lighten(0.85, theme.colors.primary);
	const primary100 = lighten(0.7, theme.colors.primary);
	const primary200 = lighten(0.55, theme.colors.primary);
	const primary400 = lighten(0.15, theme.colors.primary);
	const primary500 = theme.colors.primary;
	const primary600 = darken(0.1, theme.colors.primary);
	const primary700 = darken(0.2, theme.colors.primary);
	const primary800 = darken(0.3, theme.colors.primary);
	const primary900 = darken(0.4, theme.colors.primary);

	// const hoverBg = '#1a1a1a';
	const hoverBg =
		theme.name === 'dark'
			? lighten(0.1, theme.colors.background)
			: darken(0.1, theme.colors.background);

	return css`
		--rs-gray-0: ${gray0};
		--rs-gray-50: ${gray50};
		--rs-gray-100: ${gray100};
		--rs-gray-200: ${gray200};
		--rs-gray-300: ${gray300};
		--rs-gray-400: ${gray400};
		--rs-gray-500: ${gray500};
		--rs-gray-600: ${gray600};
		--rs-gray-700: ${gray700};
		--rs-gray-800: ${gray800};
		--rs-gray-900: ${gray900};

		--rs-primary-50: ${primary50};
		--rs-primary-100: ${primary100};
		--rs-primary-200: ${primary200};
		--rs-primary-400: ${primary400};
		--rs-primary-500: ${primary500};
		--rs-primary-600: ${primary600};
		--rs-primary-700: ${primary700};
		--rs-primary-800: ${primary800};
		--rs-primary-900: ${primary900};

		--rs-color-red: #f44336;
		--rs-red-200: #faa9a7;
		--rs-red-400: #f7635c;
		--rs-red-500: #f44336;
		--rs-red-600: #eb3626;
		--rs-red-700: #d62915;
		--rs-red-800: #b81c07;
		--rs-red-900: #8f1300;
		--rs-orange-200: #fcc690;
		--rs-orange-400: #fa9b2f;
		--rs-orange-500: #fa8900;
		--rs-orange-600: #f08800;
		--rs-orange-700: #db8000;
		--rs-orange-800: #bd7100;
		--rs-orange-900: #945b00;
		--rs-yellow-200: #ffd991;
		--rs-yellow-400: #ffbe30;
		--rs-yellow-500: #ffb300;
		--rs-yellow-600: #f5af00;
		--rs-yellow-700: #e0a500;
		--rs-yellow-800: #c29100;
		--rs-yellow-900: #997500;
		--rs-green-200: #a5e0a4;
		--rs-green-400: #65bf67;
		--rs-green-500: #4caf50;
		--rs-green-600: #37ab3c;
		--rs-green-700: #22a12a;
		--rs-green-800: #0f9119;
		--rs-green-900: #007d0c;
		--rs-cyan-200: #87e6ed;
		--rs-cyan-400: #2acadb;
		--rs-cyan-500: #00bcd4;
		--rs-cyan-600: #00b1cc;
		--rs-cyan-700: #00a0bd;
		--rs-cyan-800: #008aa6;
		--rs-cyan-900: #006e87;
		--rs-blue-200: #9bd4fa;
		--rs-blue-400: #49abf5;
		--rs-blue-500: #2196f3;
		--rs-blue-600: #1787e8;
		--rs-blue-700: #0d73d4;
		--rs-blue-800: #045cb5;
		--rs-blue-900: #00448c;
		--rs-violet-200: #b6a1e3;
		--rs-violet-400: #805ac7;
		--rs-violet-500: #673ab7;
		--rs-violet-600: #5f2bb3;
		--rs-violet-700: #531ba8;
		--rs-violet-800: #470c99;
		--rs-violet-900: #390085;
		--rs-state-error: var(--rs-color-red);

		--rs-text-primary: ${gray800};
		--rs-text-secondary: ${gray600};
		--rs-text-inverse: ${gray50};
		--rs-text-active: ${primary700};
		--rs-text-disabled: ${gray600};

		--rs-text-error: var(--rs-color-red);
		--rs-text-highlight-bg: #fff6c9;

		--rs-border-primary: ${theme.colors.line};
		--rs-border-secondary: ${gray100};

		--rs-bg-backdrop: ${transparentize(0.2, '#000')};
		--rs-bg-overlay: ${theme.colors.background};
		--rs-bg-well: ${gray50};
		--rs-bg-active: ${primary500};

		--rs-state-hover-bg: ${primary50};
		--rs-color-focus-ring: rgb(from #ededed !important r g b / 25%);
		--rs-state-focus-shadow: 0 0 0 3px
			rgb(from #ededed !important r g b / 25%);
		--rs-state-error-outline: 3px solid rgb(from #f44336 r g b / 25%);
		--rs-shadow-overlay: 0 4px 4px rgba(0, 0, 0, 0.12),
			0 0 10px rgba(0, 0, 0, 0.06);

		--rs-btn-default-bg: ${gray50};
		--rs-btn-default-text: ${gray800};
		--rs-btn-default-hover-bg: ${gray200};
		--rs-btn-default-active-bg: ${gray300};
		--rs-btn-default-active-text: ${gray900};
		--rs-btn-default-disabled-bg: ${gray50};
		--rs-btn-default-disabled-text: ${gray600};
		--rs-btn-primary-bg: ${primary500};
		--rs-btn-primary-text: ${gray0};
		--rs-btn-primary-hover-bg: ${primary600};
		--rs-btn-primary-active-bg: ${primary700};
		--rs-btn-subtle-text: ${gray800};
		--rs-btn-subtle-hover-bg: ${gray200};
		--rs-btn-subtle-hover-text: ${gray800};
		--rs-btn-subtle-active-bg: ${gray200};
		--rs-btn-subtle-active-text: ${gray900};
		--rs-btn-subtle-disabled-text: ${gray400};
		--rs-btn-ghost-border: ${primary700};
		--rs-btn-ghost-text: ${primary700};
		--rs-btn-ghost-hover-border: ${primary800};
		--rs-btn-ghost-hover-text: ${primary800};
		--rs-btn-ghost-active-border: ${primary900};
		--rs-btn-ghost-active-text: ${primary900};
		--rs-btn-link-text: ${theme.colors.title};
		--rs-btn-link-hover-text: ${primary800};
		--rs-btn-link-active-text: ${primary900};

		--rs-loader-ring: rgb(from #f7f7fa r g b / 80%);
		--rs-loader-rotor: ${gray500};
		--rs-loader-backdrop: rgb(from #fff r g b / 90%);
		--rs-loader-ring-inverse: rgb(from #f7f7fa r g b / 30%);
		--rs-loader-rotor-inverse: ${gray0};
		--rs-loader-backdrop-inverse: rgb(from #272c36 r g b / 83%);
		--rs-input-bg: ${theme.colors.background};

		--rs-input-focus-border: ${primary100};
		--rs-input-disabled-bg: ${gray50};
		--rs-listbox-option-group-bg: ${gray0};
		--rs-listbox-option-hover-bg: ${hoverBg};
		--rs-listbox-option-hover-text: ${primary700};
		--rs-close-button-hover-color: var(--rs-color-red);

		--rs-picker-value: ${primary700};
		--rs-picker-count-bg: ${theme.colors.surface};
		--rs-picker-count-text: ${theme.colors.text};

		--rs-checkbox-icon: ${theme.colors.background};
		--rs-checkbox-border: ${gray300};
		--rs-checkbox-checked-bg: ${primary500};
		--rs-checkbox-disabled-bg: ${gray50};

		--rs-calendar-today-bg: ${primary500};
		--rs-calendar-today-text: #fff;
		--rs-calendar-range-bg: ${hoverBg};
		--rs-calendar-time-unit-bg: ${gray50};
		--rs-calendar-date-selected-text: ${readable(theme.colors.primary)};
		--rs-calendar-cell-selected-hover-bg: ${primary700};
	`;
}
