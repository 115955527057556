import Cookies from 'js-cookie';

import { app } from 'config/app';

export const getCookieKey = (name: string) => [app.id, name].join('_');

export const cookies = {
	get: (name: string) => Cookies.get(getCookieKey(name)),
	remove: (name: string, options?: Cookies.CookieAttributes) =>
		Cookies.remove(getCookieKey(name), options),
	set: (name: string, value: string, options?: Cookies.CookieAttributes) =>
		Cookies.set(getCookieKey(name), value, options),
};
