import { Theme } from 'models/Theme';

export const dark: Theme = {
	name: 'dark',

	colors: {
		primary: '#fff',
		secondary: '#7297c5',

		background: 'rgb(6, 6, 6)',
		// surface: 'rgb(25, 25, 25)',
		surface: 'rgb(16, 16, 16)',
		line: 'rgb(255 255 255 / 15%)',

		title: '#FFFFFF',
		text: '#d1d5db',
	},
};

export const light: Theme = {
	name: 'light',

	colors: {
		primary: '#111',
		secondary: '#7297c5',

		background: '#ffffff',
		surface: '#f1f3f4',
		line: '#e7e7e9',

		title: 'rgb(0 0 0 / 100%)',
		text: 'rgb(0 0 0 / 75%)',
	},
};

export const themes: Record<Theme['name'], Theme> = {
	dark,
	light,
};
