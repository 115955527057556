/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { useRouter } from 'next/router';

import { useBoolean, UseBooleanReturn } from 'codekit';
import { toast } from 'sonner';

import { routes } from 'config/routes';
import { useFetch } from 'hooks/useFetch';
import { useTheme } from 'hooks/useTheme';
import { Organization } from 'models/Organization';
import { useAuth } from './AuthContext';

interface DashboardContextProps {
	children: React.ReactNode;
}

export type Tab =
	| 'dashboard'
	//
	| 'projects'
	| 'users'
	| 'usage'
	| 'settings'
	//
	| 'balancete'
	| 'finance'
	| 'history';

export type Project = {
	id: string;
	name: string;
};

export interface DashboardContextData {
	project: Project | null;
	setProject: (projectId: Project['id'] | null) => void;

	tab: Tab;
	setTab: (tab: Tab) => void;

	goTo: (tab: Tab, projectId: string | null) => Promise<boolean>;

	organization: ReturnType<typeof useFetch<Organization>>;
	projects: ReturnType<typeof useFetch<Project[]>>;

	isGeneratingBalancete: UseBooleanReturn;
}

export const DashboardContext = createContext({} as DashboardContextData);

export const tabMap: Record<Tab, string> = {
	dashboard: 'Dashboard',
	//
	projects: 'Projetos',
	usage: 'Plano',
	settings: 'Configurações',
	users: 'Usuários',
	//
	balancete: 'Balancete',
	finance: 'Demostrações financeiras',
	history: 'Histórico',
};

export const tabs = {
	'selected-project': ['balancete', 'dashboard', 'history'],
	'unselected-project': [
		'dashboard',
		'projects',
		'usage',
		'users',
		'settings',
	],
} satisfies Record<string, Tab[]>;

export const DashboardProvider = ({ children }: DashboardContextProps) => {
	// Hooks
	const auth = useAuth();
	const router = useRouter();
	const theme = useTheme();

	// States
	const [tab, setTab] = useState<Tab>(
		([router.query.tab].flat().compact()?.[0] as Tab) ?? 'dashboard',
	);
	const [projectId, setProjectId] = useState<Project['id'] | null>(
		[router.query.projectId].flat().compact()?.[0] ?? null,
	);

	// Boolean hooks
	const isGeneratingBalancete = useBoolean();

	// Refs
	const isFetchedTheme = useRef<boolean>(false);

	// Fetchers
	const organization = useFetch<Organization>(null);

	const projects = useFetch<Project[]>(
		auth.isAuthenticated && auth.user ? `/projects/` : null,
	);

	// Memo vars
	const project = useMemo(() => {
		return (
			projects.data?.find((project) => project.id === projectId) ?? null
		);
	}, [projectId, projects.data]);

	// Callbacks
	const changeTab = useCallback(
		(tab: Tab) => {
			if (isGeneratingBalancete.value) {
				return toast.info(
					'Você não pode sair dessa página enquanto o processamento ainda estiver em andamento',
				);
			}

			setTab(tab);

			return router.push(
				routes
					.build('panel.[tab].[projectId]', tab, projectId || '')
					.replace('[projectId]', ''),
				undefined,
				{
					shallow: true,
				},
			);
		},
		[isGeneratingBalancete.value, projectId, router],
	);

	const changeProject = useCallback(
		(projectId: string | null) => {
			if (isGeneratingBalancete.value) {
				return toast.info(
					'Você não pode sair dessa página enquanto o processamento ainda estiver em andamento',
				);
			}

			setProjectId(projectId);
			setTab('balancete');

			return router.push(
				routes
					.build(
						'panel.[tab].[projectId]',
						'dashboard',
						projectId ?? '',
					)
					.replace('[projectId]', ''),
				undefined,
				{
					shallow: true,
				},
			);
		},
		[isGeneratingBalancete.value, router],
	);

	const goTo = useCallback(
		(tab: Tab, projectId: string | null) => {
			setProjectId(projectId);
			setTab(tab);

			return router.push(
				routes
					.build('panel.[tab].[projectId]', tab, projectId ?? '')
					.replace('[projectId]', ''),
				undefined,
				{
					shallow: true,
				},
			);
		},
		[router],
	);

	// Effects
	useEffect(() => {
		if (isFetchedTheme.current) return;

		isFetchedTheme.current = true;
		if (auth.user?.theme) theme.set(auth.user?.theme);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [auth.user?.theme]);
	// useEffect(() => {
	// 	for (const tab of Object.values(tabs).flat()) {
	// 		router.prefetch(
	// 			routes
	// 				.build('panel.[tab].[projectId]', tab, projectId || '')
	// 				.replace('[projectId]', ''),
	// 		);
	// 	}
	// }, [projectId, router]);

	return (
		<DashboardContext.Provider
			value={{
				projects,

				project,
				setProject: changeProject,

				tab,
				setTab: changeTab,

				goTo,

				organization,

				isGeneratingBalancete,
			}}
		>
			{children}
		</DashboardContext.Provider>
	);
};

export const useDashboard = () => useContext(DashboardContext);

export function withDashboardContext<P extends object>(
	Page: (props: P) => JSX.Element,
) {
	// eslint-disable-next-line react/display-name
	return (props: P) => (
		<DashboardProvider>
			<Page {...props} />
		</DashboardProvider>
	);
}
