/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, useMemo, useState } from 'react';

import { useIsomorphicLayoutEffect } from 'codekit';
import Cookies from 'js-cookie';

import { Theme, ThemeName } from '../models/Theme';
import { themes } from '../theme';

interface ThemeContextProps {
	children: React.ReactNode;
}

export interface ThemeContextData extends Theme {
	toggle: () => void;
	set: (theme: ThemeName) => void;
}

export const ThemeContext = createContext({} as ThemeContextData);

const DEFAULT_THEME: Theme['name'] = 'dark';

export const ThemeProvider = ({ children }: ThemeContextProps): JSX.Element => {
	// States
	const [theme, setTheme] = useState<Theme['name']>(DEFAULT_THEME);

	// Memo vars
	const currentTheme = useMemo(() => themes[theme], [theme]);

	// Effects
	useIsomorphicLayoutEffect(() => {
		const theme = (Cookies.get('theme') || DEFAULT_THEME) as Theme['name'];

		setTheme(theme);
	}, []);

	// Functions
	function toggleTheme() {
		const newTheme = theme === 'light' ? 'dark' : 'light';

		changeTheme(newTheme);
	}

	function changeTheme(theme: ThemeName) {
		setTheme(theme);

		localStorage.setItem('theme', theme);
		Cookies.set('theme', theme, {
			expires: 9999,
		});
	}

	return (
		<ThemeContext.Provider
			value={{
				...currentTheme,
				toggle: toggleTheme,
				set: changeTheme,
			}}
		>
			{children}
		</ThemeContext.Provider>
	);
};
