import {
	darken as PolishedDarken,
	getLuminance,
	lighten as PolishedLighten,
	readableColor as PolishedReadable,
	transparentize as PolishedTransparentize,
} from 'polished';

export function readable(color: string) {
	const luminance = getLuminance(color);

	return luminance > 0.7 ? '#000' : '#fff';
}

export function highlight(color: string, amount: number): string {
	const luminance = getLuminance(color);

	return luminance > 0.5
		? PolishedDarken(amount, color)
		: PolishedLighten(amount, color);
}

export function lighten(color: string, amount: number) {
	return PolishedLighten(amount, color);
}

export function darken(color: string, amount: number) {
	return PolishedDarken(amount, color);
}

export function transparentize(color: string, amount: number) {
	return PolishedTransparentize(amount, color);
}

export function transparentizeReadable(color: string, amount: number) {
	return PolishedTransparentize(amount, PolishedReadable(color));
}
