import axios from 'axios';

import { authTokenCookieKey } from 'contexts/AuthContext';
import { cookies } from 'utils/cookies';

export const api = axios.create({
	baseURL: 'https://api.balancete.ai',
});

api.interceptors.request.use(
	(config) => {
		const token = cookies.get(authTokenCookieKey);

		if (token) config.headers.Authorization = `Bearer ${token}`;
		if (config.url?.endsWith('/'))
			config.url = config.url.split('/').slice(0, -1).join('/');

		return config;
	},
	(error) => Promise.reject(error),
);

api.interceptors.response.use(
	(response) => response,
	async (error) => {
		const token = cookies.get(authTokenCookieKey);

		if (error.response?.status === 401 && token) {
			cookies.remove(authTokenCookieKey);
			// window.location.href = '/auth/login';
			return;
		}

		if (error.response?.data?.detail) {
			error.response.data.error = error.response.data.detail;
		}

		return Promise.reject(error);
	},
);
