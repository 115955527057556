/* eslint-disable @typescript-eslint/no-explicit-any */
import { routes } from 'config/routes';

export interface User {
	id: string;
	created_at: string;
	modified_at: string;
	email: string;
	position: string;
	name: string;
	status: 'active' | 'waiting_confirmation';
	role: string;
	theme?: 'dark' | 'light';
}

export enum UserRoles {
	user = 'Usuário',
}

export type UserRole = keyof typeof UserRoles;

export interface UserPermissions {
	role: UserRole;
	mainRoute: string;
	allowedRoutes: string[];
	restrictedRoutes: string[];
}

// Defaults and Mocks
export const privateRoutes: string[] = [routes.from('panel')].flat();
export const publicRoutes: string[] = [].flat();
export const disabledAuthRoutes: string[] = publicRoutes;

export const permissions: Record<UserRole, UserPermissions> = {
	user: {
		role: 'user',
		mainRoute: routes.get('panel'),
		allowedRoutes: ['*'],
		restrictedRoutes: [],
	},
};

export const userStatusMap: Record<User['status'], string> = {
	active: 'Ativo',
	waiting_confirmation: 'Aguardando confirmação',
};

export const userStatusColorMap: Record<User['status'], string> = {
	active: '#50e3c2',
	waiting_confirmation: '#f5a623',
};
