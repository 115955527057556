import styled from 'styled-components';

export const ButtonContainer = styled.div`
	.aresui-button-variant-default {
		&:hover {
			transform: none !important;
		}
	}
`;

export default ButtonContainer;
