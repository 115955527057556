export const relations: Record<string, string> = {};

export const references = {
	'/': {},
	'#': {},
	'*': {},

	auth: {
		login: {},
		register: {},
		logout: {},
		otp: {},
	},

	panel: {
		onboarding: {},
		dashboard: {},
		'[tab]': {
			'[projectId]': {},
		},
	},
};
