import { useEffect, useState } from 'react';
import Error from 'next/error';
import { useRouter } from 'next/router';

import LoadingFullScreen from 'components/LoadingFullscreen';
import { AuthContextData, useAuth } from 'contexts/AuthContext';

export interface ProtectRouteProps {
	children: JSX.Element | React.ReactNode;
}

export type Render = 'undefined' | 'loading' | 'page' | 'denied';

declare global {
	interface Window {
		auth: AuthContextData;
	}
}

function ProtectRoute(props: ProtectRouteProps): JSX.Element {
	// Hooks
	const router = useRouter();
	const auth = useAuth();

	// States
	const [render, setRender] = useState<Render>();

	// Vars
	const NotAuthorized = () => (
		<Error statusCode={401} title="Não autorizado" />
	);

	// Effects
	useEffect(() => {
		const p = auth.permissions || null;
		const r = router.route;
		const isLoading = auth.isLoading;

		window.auth = auth;

		const userAlreadyLoggedIn = auth.verifyIfUserAlreadyLoggedIn();
		const isAuthDisabled = auth.verifyIfRouteIsAuthDisabled(r);
		const isPublicRoute = auth.verifyIfRouteIsPublic(r);
		const isPrivateRoute = auth.verifyIfRouteIsPrivate(r);
		const isAllowedRoute = auth.verifyIfRouteIsAllowed(p, r);

		if (isAuthDisabled) return setRender('page');
		if (isLoading && isPrivateRoute) return setRender('loading');
		if (isLoading && isPublicRoute) return setRender('page');
		if (isLoading && userAlreadyLoggedIn) return setRender('loading');
		if (isAllowedRoute) return setRender('page');
		if (!isAllowedRoute) return setRender('denied');
	}, [auth, router.route]);

	useEffect(() => {
		if (auth.isAuthenticated || auth.isLoading) return;

		const origin = window.location.pathname;
		const userSignOut = sessionStorage.getItem('user:logout');
		const route = router.route;

		let backPath = '/auth/login';

		const isAuthDisabledRoute = auth.verifyIfRouteIsAuthDisabled(route);
		if (isAuthDisabledRoute) return;
		if (auth.isPublicRoute) return;

		if (!userSignOut) {
			alert('Você precisa estar logado para acessar essa página.');
			auth.logout();
			backPath += `?origin=${origin}`;
		}

		router
			.push(backPath)
			.then(() => sessionStorage.removeItem('user:logout'));
	}, [auth, router, router.route]);

	if (!render) return <></>;

	if (render === 'undefined') return <></>;
	if (render === 'loading') return <LoadingFullScreen />;
	if (render === 'page') return props.children as JSX.Element;
	if (render === 'denied') return <NotAuthorized />;

	return <></>;
}

export default ProtectRoute;
