import {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { useRouter } from 'next/router';

import { delay, useBoolean } from 'codekit';

import ProtectRoute from 'components/ProtectRoute';
import {
	disabledAuthRoutes,
	permissions,
	privateRoutes,
	publicRoutes,
	User,
	UserPermissions,
} from 'models/Auth/User';
import { Organization } from 'models/Organization';
import { api } from 'services/api';
import { cookies } from 'utils/cookies';

export interface AuthContextProps {
	children: React.ReactNode | JSX.Element;
}

interface UserMe extends User {
	organization: Organization;
}

export interface AuthContextData {
	user: UserMe | null;
	permissions: UserPermissions;

	isAuthenticated: boolean;
	isLoading: boolean;
	isPrivateRoute: boolean;
	isPublicRoute: boolean;

	verifyIfUserAlreadyLoggedIn: () => boolean;
	verifyIfRouteIsPrivate: (route: string) => boolean;
	verifyIfRouteIsPublic: (route: string) => boolean;
	verifyIfRouteIsAuthDisabled: (route: string) => boolean;
	verifyIfRouteIsAllowed: (p: UserPermissions | null, r: string) => boolean;

	login: (token: string) => Promise<void>;
	logout: () => Promise<void>;

	refetch: () => Promise<void>;
}

export const authTokenCookieKey = `access-token`;

const AuthContext = createContext({} as AuthContextData);

export function AuthProvider(props: AuthContextProps) {
	// Hooks
	const router = useRouter();

	// States
	const [user, setUser] = useState<UserMe | null>(null);

	// Boolean hooks
	const isLoading = useBoolean(true);

	// Memo vars
	const isPrivateRoute = useMemo(() => {
		return verifyIfRouteIsPrivate(router.route);
	}, [router.route]);

	const isPublicRoute = useMemo(() => {
		return verifyIfRouteIsPublic(router.route);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [router.route]);

	// Callbacks
	const fetchUser = useCallback(async () => {
		const token = cookies.get(authTokenCookieKey);
		if (!token) return isLoading.setFalse();

		const user = await api
			.get('/users/me')
			.then((response) => response.data as UserMe)
			.catch(() => null);

		if (!user) cookies.remove(authTokenCookieKey);

		setUser(user);
		isLoading.setFalse();
	}, [isLoading]);

	// Effects
	useEffect(() => {
		fetchUser();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Functions
	async function login(token: string) {
		cookies.set(authTokenCookieKey, token, {
			expires: 99999,
			secure: true,
		});
		await delay(1);
		await fetchUser();
	}

	async function logout() {
		cookies.remove(authTokenCookieKey);
	}

	function verifyIfRouteIsPrivate(route: string) {
		const isPrivateRoute = privateRoutes.some((privateRoute) => {
			const regex = new RegExp(privateRoute.replace('*', '.*'));

			return regex.test(route);
		});

		return isPrivateRoute;
	}

	function verifyIfRouteIsPublic(route: string) {
		const isPublicRoute = publicRoutes.some((publicRoute) => {
			const regex = new RegExp(publicRoute.replace('*', '.*'));

			return regex.test(route);
		});

		const isPrivateRoute = verifyIfRouteIsPrivate(route);

		return isPublicRoute || (!isPublicRoute && !isPrivateRoute);
	}

	function verifyIfRouteIsAllowed(
		permissions: UserPermissions | null,
		route: string,
	) {
		// Verify if route is public
		if (!verifyIfRouteIsPrivate(route)) return true;
		if (verifyIfRouteIsPublic(route)) return true;

		// Verify if user is logged
		if (!permissions) return false;

		const restrictedRoutes = permissions.restrictedRoutes;
		const allowedRoutes = permissions.allowedRoutes;

		const isAllowed = allowedRoutes.some((allowedRoute) => {
			const regex = new RegExp(allowedRoute.replace('*', '.*'));

			return regex.test(route);
		});

		const isRestricted = restrictedRoutes.some((restrictedRoute) => {
			const regex = new RegExp(restrictedRoute.replace('*', '.*'));

			return regex.test(route);
		});

		return isAllowed && !isRestricted;
	}

	function verifyIfRouteIsAuthDisabled(route: string) {
		return disabledAuthRoutes.includes(route);
	}

	function verifyIfUserAlreadyLoggedIn() {
		const token = cookies.get(authTokenCookieKey);

		if (!token) return false;

		return true;
	}

	return (
		<AuthContext.Provider
			value={{
				user,
				permissions: permissions.user,

				isAuthenticated: !!user && !!permissions,
				isLoading: isLoading.value,
				isPrivateRoute,
				isPublicRoute,

				verifyIfRouteIsPrivate,
				verifyIfRouteIsPublic,
				verifyIfRouteIsAllowed,
				verifyIfRouteIsAuthDisabled,
				verifyIfUserAlreadyLoggedIn,

				login,
				logout,

				refetch: fetchUser,
			}}
		>
			<ProtectRoute>{props.children}</ProtectRoute>
		</AuthContext.Provider>
	);
}

export const useAuth = () => useContext(AuthContext);
