import { useMemo } from 'react';

import useSWR, { Key, SWRConfiguration } from 'swr';

import { api } from 'services/api';

interface Options extends SWRConfiguration {}

export function useFetch<T>(key: Key, options?: Options) {
	// Memo vars
	const fetcher = useMemo(() => {
		const instance = api;

		return (url: string) => instance.get(url).then((res) => res.data);
	}, []);

	// SWR
	const response = useSWR<T>(key, fetcher, {
		keepPreviousData: true,
		...(options ?? {}),
	});

	return response;
}
